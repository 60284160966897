<template>
  <div class="container">
    <edit-header
      entity="tag"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew"></edit-header>
    <form
      v-disable-all="!can(uiPermissions.TAGS_UPDATE)"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row">
        <div class="col">
          <div data-test="card-tag" class="card">
            <div class="card-header">
              <h3 class="card-title">Tags tree</h3>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="form-label">Category <span class="text-danger">*</span></label>
                <select
                  v-model="item.category_id"
                  data-test="input-category"
                  class="form-control"
                  required>
                  <option :value="null">Select a category</option>
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id">
                    {{category.name}}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label class="form-label">Parent tag</label>
                <input
                  v-model="item.name"
                  type="text"
                  data-test="input-parent-tag"
                  class="form-control"
                  :class="{ 'is-invalid': hasErrorResponse }"
                  required>
                <small v-if="hasErrorResponse" class="error text-danger">{{response.error.name.join(', ')}}</small>
              </div>
              <div
                v-if="item.name.length"
                data-test="div-sub-tag"
                class="pl-6">
                <label
                  v-if="item.sub_tags.length"
                  data-test="label-sub-tag"
                  class="form-label">Sub-tag
                </label>
                <ul class="category-tree">
                  <category-tree
                    v-for="(child, index) in item.sub_tags"
                    :key="index"
                    class="item"
                    :entity="'tag'"
                    :sub-category-key="'sub_tags'"
                    :item="child"
                    :index="index"
                    :response="response"
                    :parent="item"
                    @make-folder="makeFolder"
                    @add-item="addItem"
                    @remove-item="removeItem"></category-tree>
                  <li v-if="item.sub_tags.length" class="pl-5">
                    <button
                      data-test="btn-add-sub-tag"
                      class="add btn btn-primary btn-sm mt-4"
                      @click.prevent="addItem(item)">
                      <i class="fe fe-plus"></i> Add sub-tag for {{item.name}}
                    </button>
                  </li>
                </ul>
              </div>
              <button
                v-if="!item.sub_tags.length && item.name.length"
                data-test="btn-add-first-sub-tag"
                class="add btn btn-primary btn-sm mt-4"
                @click.prevent="addItem(item)">
                <i class="fe fe-plus"></i> Add sub-tag for {{item.name}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="tag"
            :isNew="isNew"
            :submitting="submitting"
            :hide-delete="true"></edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import CategoryTree from '@/components/CategoryTree';
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import edit from '@/mixins/edit';
import {tags} from '@/services';

export default {
  components: {
    CategoryTree,
    EditFooter,
    EditHeader,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      item: {
        name: '',
        category_id: null,
        sub_tags: [],
      },
      removed: [],
      categories: [],
    };
  },
  computed: {
    hasErrorResponse() {
      return this.response.error?.name && this.response.data.index === null;
    },
    route() {
      return `/tags/${this.item.id}`;
    },
  },
  mounted() {
    tags.getTagCategories().then(results => this.categories = results.categories);
  },
  methods: {
    addItem(item) {
      item.sub_tags.push({

        name: '',
        category_id: null,
        parent_id: item.id ? item.id : null,
        sub_tags: [],
      });
    },
    fetchData(id) {
      const params = {
        subTags: 1,
      };
      return tags.getById(id, {params});
    },
    async handleDelete() {
      if (window.confirm('Deleting tag cannot be undone. Are you sure that you want to delete this tags?')) {
        await tags.deleteById(this.item.id);
        this.$router.push('/tags');
      }
    },
    makeFolder(item) {
      this.$set(item, 'sub_tags', []);
      this.addItem(item);
    },
    async removeItem({parent, index}) {
      const subTag = parent.sub_tags[index];

      if (subTag && subTag.id && window.confirm(`Deleting ${subTag.name} sub-tag cannot be undone. Are you sure that you want to delete this sub-tag?`)) {
        await tags.deleteById(subTag.id);
        await this.reset();
      }

      if (!subTag.id) { parent.sub_tags.splice(index, 1); }
    },
    async submitData(item) {
      const payload = {

        index: null,
        name: item.name,
        category_id: item.category_id,
        parent_id: null,
      };

      if (item.id) { payload.id = item.id; }

      this.response.data = payload;

      const parent = await tags.saveOrUpdate(payload);
      this.item.id = parent.item.id;

      // delete all removed sub categories
      if (this.removed.length) { await Promise.all(this.removed); }

      // recursively update all sub categories
      if (item.sub_tags.length) { await this.submitSubcategories(item.sub_tags, parent); }

      // refetch the tag details
      return this.fetchData(parent.item.id);
    },
    async submitSubcategories(item, parent) {
      // use regular loop, .forEach method doesn't respect async await to run posts sequentially
      for (let i = 0; i < item.length; i++) {
        const payload = {

          index: i,
          name: item[i].name,
          category_id: parent.item.category_id,
          parent_id: parent.item.id,
        };

        // case data that has ids already
        if (item[i].id) { payload.id = item[i].id; }

        // [mixin] set last post data for API error handling
        this.response.data = payload;

        // this will wait to finish then grab the created id
        const subParent = await tags.saveOrUpdate(payload);
        item[i].id = subParent.item.id;

        // keep recursing the function if the parent still have subcategories in the list
        // send the parent object that has the created ID
        if (item[i].sub_tags.length) { await this.submitSubcategories(item[i].sub_tags, subParent); }
      }

      // all nested sub-categories are processed stop and return true to complete the async/await
      if (item.every(data => !data.sub_tags.length)) { return true; }
    },
    transformData(result) {
      return result;
    },
  },
};

</script>

<style scoped>
ul.category-tree {
  margin: 0;
  padding: 0;
}

ul.category-tree .bold input {
  font-weight: bold;
}

ul.category-tree ul {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

ul.category-tree li {
  list-style: none;
  padding: 0.5rem 0;
}
</style>
